import store from '@/store';
import { computed } from 'vue';
import router from './../router';

const deviceInfo = computed(() => {
  return store.state.deviceInfo;
});
export const refreshTab = async () => {
  await store.dispatch('getUserInfo');
  await store.dispatch('getDeviceInfo');
  console.log('store.state.deviceInfo.length', store.state.deviceInfo.length);
  if (store.state.deviceInfo.length > 0) {
    store.commit('setBoundImg', false);
    // store.commit('setCurrentDeviceId', store.state.deviceInfo[0]?.['id']);
    // store.commit('setCurrentDeviceInfo', store.state.deviceInfo[0]);
    // console.log("setCurrentDeviceInfo", store.state.currentDeviceInfo)
    if (store.state.deviceInfo[0]?.['id']) {
      store.commit('setCurrentDeviceId', store.state.deviceInfo[0]?.['id']);
      store.commit('setCurrentDeviceInfo', store.state.deviceInfo[0]);
      console.log('refreshrefresh');
      store.commit('setDeviceType', 0);
    } else {
      store.commit(
        'setCurrentDeviceId',
        (store.state.deviceInfo[0] as any).devices[0]?.['id']
      );
      store.commit('setCurrentDeviceInfo', {
        ...(store.state.deviceInfo[0] as any).devices[0],
        is_expired: (store.state.deviceInfo[0] as any).is_expired,
        expire_at: (store.state.deviceInfo[0] as any).expired_at,
      });
      store.commit('setDeviceType', 1);
    }
    // 解绑之后需要重新生成已绑定设备列表
    let arr;
    let boundedDevice;
    arr = deviceInfo.value.map((item: any) => {
      if (!item.apple_id) {
        if (item.model) {
          return item.model;
        } else {
          return item.name;
        }
      } else {
        return item.apple_id;
      }
    });
    boundedDevice = arr.join('、');
    store.commit('setBoundedDevice', boundedDevice);
    // console.log('store.state.boundedDevice',store.state.boundedDevice);
  } else {
    store.commit('setDefaultMenu', null);
    router.push({ path: '/guide' });
    store.commit('setCurrentDeviceId', 'Device 1');
    store.commit('setCurrentDeviceInfo', {
      name: 'leftTab.unboundName',
      id: 'Device 1',
      model: 'leftTab.unbound',
    });
    store.commit('setBoundImg', true);
  }
  store.dispatch('updateMenuList', {
    deviceInfo: deviceInfo.value,
    getCurrent: false,
  });
};
