import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-11ccdbaa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "item" }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = { class: "leftBox" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  key: 1,
  class: "textBox"
}
const _hoisted_7 = { class: "text" }
const _hoisted_8 = { class: "rightBox" }
const _hoisted_9 = { class: "progressText" }
const _hoisted_10 = { class: "r_top" }
const _hoisted_11 = { class: "username wordHidden" }
const _hoisted_12 = {
  key: 0,
  class: "lastMsg wordHidden"
}
const _hoisted_13 = {
  key: 1,
  class: "lastCall wordHidden"
}
const _hoisted_14 = { class: "lastBox" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_progress = _resolveComponent("a-progress")!
  const _component_Tooltip = _resolveComponent("Tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_progress, {
      percent: _ctx.item.percentage,
      showInfo: false,
      class: _normalizeClass(_ctx.host == 'whatsapp' ? 'whats' : 'ins')
    }, null, 8, ["percent", "class"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (!_ctx.isVibe && _ctx.item.avatar_url)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("img", {
                src: _ctx.item.avatar_url,
                alt: "",
                class: "demoAvatar"
              }, null, 8, _hoisted_5)
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.getFirstXLetters(_ctx.item.contact_name, 3)), 1)
            ]))
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.item.contact_name), 1)
          ]),
          (_ctx.item.last_message)
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                _createVNode(_component_Tooltip, {
                  content: _ctx.item.last_message
                }, null, 8, ["content"])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_13, " Call Time:" + _toDisplayString(_ctx.dateFormat(_ctx.item.date, "YYYY-MM-DD HH:mm")), 1))
        ])
      ]),
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.host == 'whatsapp' ? 'count' : 'insText')
        }, _toDisplayString(_ctx.item.count), 3)
      ])
    ])
  ]))
}