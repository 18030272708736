
import { defineComponent, computed, watch, ref } from 'vue'
import homePage from '@/components/homePage/index.vue'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'home',
  components: {
    homePage,
  },
  setup() {
    const store = useStore()

    const isNoSetTopPadding = ref(false)
    const messageToastType = computed(() => store.state.messageToastType)
    watch(
      [messageToastType],
      () => {
        // console.log('messageToastType改变了', messageToastType);
        // if (messageToastType.value != 0) {
        //   isNoSetTopPadding.value = true
        // }
      },
      {
        immediate: true,
      }
    )

    return {
      isNoSetTopPadding,
    }
  },
})
