import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_home_page = _resolveComponent("home-page")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['home-box', _ctx.isNoSetTopPadding ? 'topPadding' : ''])
  }, [
    _createVNode(_component_home_page, { class: "'home-page'" })
  ], 2))
}